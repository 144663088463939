<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Supervisor'"
      :profile="'supervisor'"
      :store="'supervisores'"
      :validar="'CPF'"
      @close-dialog="setFields"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Dados Principais">
          <crud-pessoa-fisica-base
            ref="pf"
            v-model="nome"
            :optional="['rg']"
            :input-outlined="true"
            :disabled="disabledFields"
          />
          <crud-contato
            :telefones="telefones"
            :input-outlined="true"
          />
        </crud-card>
        <crud-card title="Vinculos">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="role"
                :loading="!roles"
                :items="roles"
                small-chips
                dense
                plearable
                rounded
                outlined
                color="blue-grey lighten-2"
                label="Papel"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                autocomplete="nope"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="unidades"
                :items="uNegocio"
                small-chips
                multiple
                item-text="razao_social"
                item-value="id"
                class="d-flex justify-space-between"
                label="Unidade de Negócio *"
                clearable
                chips
                dense
                rounded
                outlined
                autocomplete="nope"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-if="data.index < 6"
                    rounded
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.filial }}
                  </v-chip>
                  <span
                    v-if="data.index === 6"
                    class="grey--text caption"
                  >
                    (+{{ unidades.length - 6 }} outros)
                  </span>
                </template>
                <template
                  v-slot:item="data"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.filial }}</v-list-item-title>
                    <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="gestor_id"
                :items="gestores"
                small-chips
                item-text="nome"
                item-value="id"
                class="d-flex justify-space-between"
                label="Gerente"
                clearable
                chips
                dense
                rounded
                outlined
                autocomplete="nope"
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-container
                    class="rounded-lg mx-n2"
                    fluid
                  >
                    <v-row
                      dense
                      justify="space-between"
                    >
                      <div>{{ data.item.nome }}</div>
                      <div>CPF {{ data.item.cpf }}</div>
                    </v-row>
                  </v-container>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </crud-card>
        <v-row
          justify="center"
          justify-sm="end"
        >
          <v-col cols="auto">
            <v-btn
              class="mr-3"
              color="error"
              rounded
              @click="$router.push({ path: `/${$user.get().role}/supervisores` })"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="pt-3"
              color="success"
              :disabled="desabilitaBotao"
              rounded
              @click="submitForm"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              {{ isEditing ? 'Salvar' : 'Cadastrar' }}
            </v-btn>
          </v-col>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import SupervisorFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/supervisor'
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        rules,
        valid: false,
        telefones: [
          {
            tipo: 'Comercial',
            format: 'com',
            title: 'Telefone 1',
            required: true,
          },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/supervisor', ['gestores', 'roles', 'loading', 'isEditing', 'uNegocio']),
      ...mapFields('form', ['data.role', 'data.gestor_id', 'data.nome', 'data.unidades']),
      ...mapFields('form/supervisor', ['dialog']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return ['email', 'cpf']
        }
        return ['cpf']
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.supervisor) { this.$store.registerModule(['form', 'supervisor'], SupervisorFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/supervisor', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/cadastro/supervisor/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      submitForm () {
        this.$refs.pf.$v.$touch()
        if (!this.$refs.form.validate() || this.$refs.pf.$v.$invalid) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
      remove (item) {
        const index = this.unidades.indexOf(item.id)
        if (index >= 0) this.unidades.splice(index, 1)
      },
    },
  }
</script>
